import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const coffee = require('../assets/coffee.png');

export class Home extends React.Component {
  render() {
    return (
      <Wrapper>
        <Title className="Shake">Moistmen</Title>
        <LinkContainer to="/player/cosmo" className="">
          <Card>COSMO</Card>
        </LinkContainer>
        <LinkContainer to="/player/ic3" className="">
          <Card>IC3</Card>
        </LinkContainer>
        <LinkContainer to="/player/mölder" className="">
          <Card>MÖLDER</Card>
        </LinkContainer>
        <LinkContainer to="/player/jæder" className="">
          <Card>JÆDER</Card>
        </LinkContainer>
        <Img className="Pan" src={coffee} width="150px" />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  overflow-x: none;
`;

const LinkContainer = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0px 0px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 50px;
`;
const Img = styled.img`
  margin-top: 20px;
`;

const Card = styled.div`
  width: 150px;
  border-radius: 10px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin: 10px 0px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
`;
const CardTitle = styled.h2``;
const CardText = styled.p``;
