import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import colors from '../utils/colors';
import firebase from '../utils/firebase';
import { Link } from 'react-router-dom';

export class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      error: false
    };
  }

  async componentDidMount() {
    const playerName = this.props.match.params.player;
    firebase
      .ref('/flamelink/environments/production/content/player/en-US')
      .once('value', snap => {
        const data = snap.val();
        const players = Object.values(data);
        const player = players.find(p => p.name.toLowerCase() === playerName);
        if (!player) {
          this.setState({
            error: true
          });
          return;
        }
        this.setState({
          player
        });
      });
  }

  render() {
    if (!this.state.player) {
      if (this.state.error) {
        return (
          <Content>
            <Description>
              This player is not a member of the Moistmen!
            </Description>
          </Content>
        );
      }
      return (
        <Content>
          <Loader type="Puff" color="#00BFFF" height="100" width="100" />
        </Content>
      );
    }
    const {
      name,
      streamableLink,
      color,
      rank,
      description
    } = this.state.player;
    const rankIMG = require(`../assets/${rank}.png`);
    return (
      <Wrapper color={color}>
        <Title className="">{name}</Title>
        <RankContainer className="Spin">
          <img src={rankIMG} />
        </RankContainer>
        {streamableLink != '' && (
          <VideoContainer>
            <StreamableDiv>
              <IFrame
                src={streamableLink}
                frameborder="0"
                width="100%"
                height="100%"
                allowfullscreen
              />
            </StreamableDiv>
          </VideoContainer>
        )}
        {description != '' && (
          <Description>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Description>
        )}
        <Footer>
          <Description>
            <Link to="/">Go Back</Link>
          </Description>
        </Footer>
      </Wrapper>
    );
  }
}

const Description = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  border: 3px solid #333;
  max-width: 85%;
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
`;
const StreamableDiv = styled.div`
  width: 100%;
  height: 0px;
  position: relative;
  padding-bottom: 56.25%;
`;
const Title = styled.h2`
  margin: 0;
  color: black;
  -webkit-text-fill-color: #eee; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #333;
  font-size: 100px;
  font-weight: 100;
`;

const RankContainer = styled.div`
  margin: 40px 0px;
`;

const Footer = styled.div` 
  padding: 20px;
`;

const Wrapper = styled.div`
  background-color: ${({ color }) => colors[color]};
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex:1;
  min-height: 100vh;
`;

const VideoContainer = styled.div`
  width: 600px;
  max-width: 85%;
  position: relative;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px;
  border: 3px solid #333;
`;
