export const purple = "#6a417f";
export const green = "#109856";
export const blue = "#68a3e5";
export const yellow = "#dbe096";
export const orange = "#eda338";

export default {
  purple,
  green,
  blue,
  yellow,
  orange
};
