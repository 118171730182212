import React from 'react'
import styled from 'styled-components'

export const PlayerCard = ({ player }) => {
    console.log(player)
    if (!player || !player.state || !player.match_stats) {
        return null
    }
    return <Card dead={!player.state.health} low={player.state.health < 25}>
        <Name>{player.name}</Name>
        <Row>
            <SmallCard><Stat><Left>Kills</Left><Right> {player.match_stats.kills}</Right></Stat>
                <Stat><Left>Deaths</Left> <Right>{player.match_stats.deaths}</Right></Stat>
                <Stat><Left>Assists</Left> <Right>{player.match_stats.assists}</Right></Stat>
                <Stat><Left>MVP</Left> <Right>{player.match_stats.mvps}</Right></Stat>
                <Stat><Left>Score</Left> <Right>{player.match_stats.score}</Right></Stat>
            </SmallCard>
            <SmallCard>
                <Stat><Left>Health</Left> <Right>{player.state.health}</Right></Stat>
                <Stat><Left>Armor</Left> <Right>{player.state.armor}</Right></Stat >
                <Stat><Left>Defuser </Left><Right>{player.state.defusekit ? 'Yes' : 'No'}</Right ></Stat >
                <Stat><Left>Flashed </Left><Right>{player.state.flashed}</Right ></Stat >
                <Stat><Left>Smoked </Left><Right>{player.state.smoked}</Right ></Stat >
                <Stat><Left>Burning </Left><Right>{player.state.burning}</Right ></Stat >
                <Stat><Left>Money </Left><Right>{player.state.money}</Right ></Stat >
                <Stat><Left>Equipment Value </Left><Right>${player.state.equip_value}</Right ></Stat >
            </SmallCard >
        </Row >
    </Card >
}

const SmallCard = styled.div`
   border: 1px solid #000;
    border-radius: 10px;
    padding: 0px 10px;
    margin: 5px;
`

const Left = styled.p`
`
const Right = styled.p`
`

const Card = styled.div`
    border: 1px solid #000;
    border-radius: 10px;
    background-color: ${({ dead, low }) => dead ? '#333' : low ? '#900' : '#fff'};
    padding: 20px;
    margin: 20px;
    
`

const Row = styled.div`
/* display: flex; */
    /* flex-direction: row; */

`

const Name = styled.h2`

`

const Stat = styled.div`
display: flex; 
 flex-direction: row;
justify-content: space-between;
`