const firebase = require("firebase");

var config = {
  apiKey: "AIzaSyDUfkJBANGZWqILRQ1sCSLbJHJEYVDHA6M",
  authDomain: "moistmen-4244c.firebaseapp.com",
  databaseURL: "https://moistmen-4244c.firebaseio.com",
  projectId: "moistmen-4244c",
  storageBucket: "moistmen-4244c.appspot.com",
  messagingSenderId: "754040634332"
};
firebase.initializeApp(config);

export default firebase.database();
