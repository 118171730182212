import React from 'react'
import styled from 'styled-components'
import { firebaseLocal } from '../App'
import { PlayerCard } from '../component/player-card.view';

export class Live extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

  }

  componentDidMount() {
    var ref = firebaseLocal.database().ref('teams/moistmen')
    ref.on('value', (snap) => {
      this.setState({ data: snap.val(), keys: Object.keys(snap.val()) })
    })
  }

  render() {
    if (!this.state.keys) return <Wrapper></Wrapper>
    return <Wrapper>
      <Row>
      {this.state.keys.map(key => <PlayerCard key={key} player={this.state.data[key].player}></PlayerCard>)}
      </Row>
    </Wrapper>
  }
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Row = styled.div`
  display:flex;
  flex-direction: row;
`