import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./views/home.view";
import { Player } from "./views/player.view";
import { Live } from "./views/live.view";
import firebase from 'firebase'

var firebaseConfig = {
  apiKey: "AIzaSyBMYytKRnTIAHyDzinyUSn66hSmJrgLrDc",
  authDomain: "statetrakteams.firebaseapp.com",
  databaseURL: "https://statetrakteams.firebaseio.com",
  projectId: "statetrakteams",
  storageBucket: "statetrakteams.appspot.com",
  messagingSenderId: "373008277680",
  appId: "1:373008277680:web:9d50df344b0a17b0"
};
// Initialize Firebase
console.log(firebase.apps)
const exportable = firebase.initializeApp(firebaseConfig, 'statetrak');
export const firebaseLocal = exportable




class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/live" component={Live} />
          <Route path="/player/:player" component={Player} />
        </Router>
      </div>
    );
  }
}

export default App;
